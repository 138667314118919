import type { GatsbyBrowser } from 'gatsby';

import * as React from 'react';
import Layout from './src/components/Layout';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  if (!('IntersectionObserver' in window)) {
    import('intersection-observer');
    console.log(`# IntersectionObserver is polyfilled!`);
  }

  return <>{element}</>
};

import type { PageProps } from 'gatsby';

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { graphql, useStaticQuery } from 'gatsby';
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion';
import { styled } from '../gatsby-theme-stitches/stitches.config';
import { getBuildDate } from '../utils/misc';
import { layoutTransitionVariants } from '../utils/transition';
import Header from './Header';
import Footer from './Footer';
import { BuildDateContext } from './buildContext';

// FIXME type
type LayoutProps = PageProps<any>;

const Container = styled(motion.div, {
  variants: {
    mode: {
      default: {
        marginTop: 'mobileHeader',
      },
      wide: {
        marginTop: 'wideHeader',
      },
    },
  },
});

const Layout: React.FC<LayoutProps> = ({
  location,
  children,
}) => {
  const [buildDate] = React.useState(getBuildDate);
  const data = useStaticQuery(graphql`
    query Layout {
      site {
        siteMetadata {
          siteUrl
          title
          description
        }
      }
    }
  `);

  const { site: { siteMetadata } } = data;

  return (
    <BuildDateContext.Provider value={buildDate}>
      <Helmet>
        <html prefix="og: http://ogp.me/ns#" lang="ko" />

        <title>{siteMetadata.title}</title>
        <meta name="description" content={siteMetadata.description} />

        <meta property="og:type" content="website" />
        <meta property="og:locale" content="ko" />
        <meta property="og:url" content={siteMetadata.siteUrl + location.pathname} />
        <meta property="og:title" content={siteMetadata.title} />
        <meta property="og:description" content={siteMetadata.description} />
      </Helmet>
      <Header location={location} />
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence>
          <Container
           variants={layoutTransitionVariants}
           initial="initial"
           animate="enter"
           exit="exit"
            mode={{ initial: 'default', md: 'wide' }}
          >
            {children}
          </Container>
        </AnimatePresence>
      </AnimateSharedLayout>
      <Footer/>
    </BuildDateContext.Provider>
  );
};

export default React.memo(Layout);

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DevTech - 데브시스터즈 기술블로그","short_name":"DevTech","background_color":"#fff","theme_color":"#ff5f00","icon":"src/assets/devtech-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9dce5f969c7c2888a235a394755ba1a5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/web-sites/web-sites/tech-story.devsisters.com/src/components/Layout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-QV9CB61C0V"],"pluginConfig":{"head":true,"exclude":["/preview/**"]},"defaultDataLayer":{"platform":"gatsby"},"routeChangeEventName":"route-change"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]

import { rem, em } from 'polished';
import { createStyled } from '@stitches/react';

export const { styled, css } = createStyled({
  breakpoints: {
    xs: rule => `@media (min-width: ${em(320)}) { ${rule} }`,
    sm: rule => `@media (min-width: ${em(360)}) { ${rule} }`,
    md: rule => `@media (min-width: ${em(786)}) { ${rule} }`,
    lg: rule => `@media (min-width: ${em(1200)}) { ${rule} }`,
    xl: rule => `@media (min-width: ${em(1920)}) { ${rule} }`,
  },
  tokens: {
    colors: {
      primary: '#fd7622',
      text: '#333',
      lowContrast: '#666',
      background: '#fff',
      border: '#e6e6e6',
    },
    fonts: {
      normal: 'Noto Sans KR, sans-serif',
    },
    sizes: {
      mobileHeader: rem(80),
      wideHeader: rem(100),
    },
    space: {
      mobileHeader: rem(80),
      wideHeader: rem(100),
    },
  },
  utils: {
    $contentWrapper: _config => _value => ({
      padding: '0 2rem',
      boxSizing: 'border-box',
      width: '100%',
      margin: '0 auto',
      maxWidth: rem(960),
      xl: {
        maxWidth: rem(1200),
      },
    }),
    $animatedUnderline: _config => _value => ({
      display: 'inline',
      backgroundImage: 'linear-gradient(transparent 90%, #fd7622 10%)',
      backgroundRepeat: 'no-repeat',
      transition: 'background-size: 0.3s ease-in-out 0s',
      backgroundSize: '0% 100%',
    }),
    $animatedUnderlineActive: _config => _value => ({
      backgroundSize: '100% 100%',
    }),
    marginX: config => value => ({
      marginLeft: value,
      marginRight: value,
    }),
    marginY: config => value => ({
      marginTop: value,
      marginBottom: value,
    }),
    paddingY: config => value => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    paddingX: config => value => ({
      paddingLeft: value,
      paddingRight: value,
    }),
  },
});

css.global({
  '*': {
    fontFamily: 'inherit',
  },
  body: {
    fontFamily: 'normal',
    wordBreak: 'keep-all',
    margin: 0,
    textRendering: 'optimizeLegibility',
    // @ts-ignore
    WebkitFontSmoothing: 'antialiased',
  },
});

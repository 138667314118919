import * as React from 'react';
import { rem } from 'polished';
import { DevsistersCi } from '@devsisters/ui-common/lib/identity';
import { styled, css } from '../gatsby-theme-stitches/stitches.config';
import { BuildDateContext } from './buildContext';

type FooterProps = {
};

const invertTheme = css.theme({
  colors: {
    background: '#fd7622',
    text: '#fff',
  },
});

const Container = styled('footer', {
  color: 'text',
  backgroundColor: 'background',
  height: rem(140),
});

const ContentWrapper = styled('div', {
  $contentWrapper: true,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
});

const BrandLogo = styled('div', {
  marginBottom: rem(8),
});

const Footer: React.FC = () => {
  const buildDate = React.useContext(BuildDateContext);
  return (
    <Container className={invertTheme}>
      <ContentWrapper>
        <BrandLogo>
          <DevsistersCi />
        </BrandLogo>
        © {buildDate.getFullYear()} Devsisters Corp. All Rights Reserved.
      </ContentWrapper>
    </Container>
  );
};

export default Footer;
